// ============================================================================
// WhiteLabelService
// -----------------
// WhiteLabel module related services
// ============================================================================

// -------
// Imports
// -------
import Vue from 'vue'
import { Config } from '@/services/ConfigService'

import { store } from '@/store'

import API from '@/apis/WhiteLabelApi'

// ---------
// Internals
// ---------
const Private = {
	getWhiteLabelData: function (accountingFirmId) {
		const data = {}
		if (accountingFirmId) {
			data.accounting_firm_id = accountingFirmId
		}
		return API.getWhiteLabels(data).then(response => response.data.data)
	},
	getWhiteLabelDataOfAccountingFirm: function (accountingFirm) {
		return API.getWhiteLabelsOfAccountingFirm(accountingFirm.id).then(response => response.data.data)
	},
	updateVuetifyTheme: function (primary, secondary) {
		Vue.prototype.$vuetify.theme.primary = primary
		Vue.prototype.$vuetify.theme.secondary = secondary
		return Promise.resolve()
	},
	selectDefaultWhiteLabel: function () {
		const darkMode = Vue.prototype.$vuetify.dark
		return Private.selectWhiteLabel(darkMode ? 'dark' : 'light')
	},
	selectWhiteLabel: function (theme) {
		return new Promise((resolve, reject) => {
			const whiteLabel = store.state.whitelabel.list.find(theming => theming.theme === theme)
			if (!whiteLabel) {
				return reject(`No data was found for the theme "${theme}"`)
			}
			return store.dispatch('whitelabel/select', whiteLabel).then(() => {
				resolve(whiteLabel)
			})
		}).then(whiteLabel => {
			const promises = [
				Private.updateOrCreateAppleTouchIcon(whiteLabel),
				Private.updateOrCreateFavicon(whiteLabel),
				Private.updateBrowserTheme(whiteLabel.primary_color),
				Private.updateVuetifyTheme(whiteLabel.primary_color, whiteLabel.secondary_color)
			]
			return Promise.allSettled(promises)
		})
	},
	updateBrowserTheme: function (primary) {
		document.head.querySelector('meta[name="theme-color"]').setAttribute('content', primary)
		return Promise.resolve()
	},
	updateOrCreateAppleTouchIcon: function (whiteLabel) {
		const url = `${Config.VUE_APP_ROOT_API}/api/accounting-firms/${whiteLabel.accounting_firm_id}/white-labels/${whiteLabel.theme
			}/apple-touch-icon?last_modified=${new Date(whiteLabel.updated_at).getTime()}`
		let appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]')
		if (!appleTouchIcon) {
			appleTouchIcon = document.createElement('link')
			appleTouchIcon.rel = 'apple-touch-icon'
			document.head.appendChild(appleTouchIcon)
		}
		appleTouchIcon.href = url
		return Promise.resolve()
	},
	updateOrCreateFavicon: function (whiteLabel) {
		const url = `${Config.VUE_APP_ROOT_API}/api/accounting-firms/${whiteLabel.accounting_firm_id}/white-labels/${whiteLabel.theme
			}/favicon.ico?last_modified=${new Date(whiteLabel.updated_at).getTime()}`
		let favicon = document.querySelector('link[rel="shortcut icon"]')
		if (!favicon) {
			favicon = document.createElement('link')
			favicon.rel = 'shortcut icon'
			document.head.appendChild(favicon)
		}
		favicon.href = url
		return Promise.resolve()
	}
}

// -------
// Exports
// -------
export default {
	loadWhiteLabelData: function () {
		let accountingFirmId = null
		const urlSearchParams = new URLSearchParams(window.location.search)
		if (urlSearchParams.has('a_id')) {
			accountingFirmId = urlSearchParams.get('a_id')
		} else if (localStorage.getItem('lastSelectedAccountingFirmId') && localStorage.getItem('token')) {
			accountingFirmId = localStorage.getItem('lastSelectedAccountingFirmId')
		} else {
			localStorage.removeItem('lastSelectedAccountingFirmId')
		}

		return Private.getWhiteLabelData(accountingFirmId)
			.catch(err => {
				if (!accountingFirmId) {
					throw err
				}
				return Private.getWhiteLabelData()
			})
			.then(whiteLabelData => {
				let result = Promise.resolve()
				if (whiteLabelData.hasOwnProperty('redirect') && whiteLabelData.redirect) {
					window.location.href = whiteLabelData.location;
				} else {
					if (location.pathname.includes('/oops')) {
						location.href = location.origin
					}

					const loadedAccountingFirmId = whiteLabelData.white_labels[0].accounting_firm_id

					store.dispatch('modules/setList', whiteLabelData.modules)

					result = store.dispatch('whitelabel/set', { ...whiteLabelData, accountingFirmId: loadedAccountingFirmId, list: whiteLabelData.white_labels })
				}
				return result
			})
			.then(() => {
				Private.selectDefaultWhiteLabel()
			})
			.catch(() => {
				if (!window.location.pathname.includes('/oops')) {
					location.href = `${location.origin}/oops`
				}
			})
	},
	onAccountingFirmSelected: function (accountingFirm) {
		let result = Promise.resolve()
		if (store.state.whitelabel.accountingFirmId !== accountingFirm.id) {
			result = Private.getWhiteLabelDataOfAccountingFirm(accountingFirm)
				.then(whiteLabelData => {
					return store.dispatch('whitelabel/set', { accountingFirmId: accountingFirm.id, name: accountingFirm.name, list: whiteLabelData })
				})
				.then(() => {
					return Private.selectDefaultWhiteLabel()
				})
		}
		return result
	},
	onWhiteLabelUpdated: function (whiteLabel) {
		return new Promise(resolve => {
			store.state.whitelabel.list.forEach(wl => {
				if (wl.accounting_firm_id === whiteLabel.accounting_firm_id && wl.theme === whiteLabel.theme) {
					Object.assign(wl, whiteLabel)
				}
			})
			resolve()
		}).then(() => {
			const selectedWhiteLabel = store.state.whitelabel.selected
			if (selectedWhiteLabel.accounting_firm_id === whiteLabel.accounting_firm_id && selectedWhiteLabel.theme === whiteLabel.theme) {
				Private.selectWhiteLabel(whiteLabel.theme)
			}
		})
	},
	setDarkMode: function (isDark) {
		return new Promise(resolve => {
			if (isDark) {
				localStorage.setItem('darkMode', 'dark')
			} else {
				localStorage.setItem('darkMode', 'light')
			}

			return store
				.dispatch('user/setDarkMode', isDark)
				.then(() => {
					return Private.selectWhiteLabel(isDark ? 'dark' : 'light')
				})
				.then(() => {
					return resolve()
				})
		})
	},
	getAuthProviders: function () {
		return store.state.whitelabel.oauth_providers
	}
}
