// ============================================================================
// AppService
// ----------
// App module related services
// ============================================================================

// -------
// Imports
// -------
import Vue from 'vue'

import { router as ROUTER } from '@/router'
import { store as STORE } from '@/store'

import { i18n } from '@/plugins/i18n'

import API from '@/apis/AppApi'
import { Events as EVENTS, Bus as BUS } from '@/events/AppEvents'

import Gest4UApi from '@/apis/ThirdParty/Gest4UApi'

// ---------
// Internals
// ---------
const Private = {
	GEST4U: 'gest4u',
	goToNewTab: function (askedRoute) {
		if (!askedRoute) {
			throw new Error('Missing "asked" argument.')
		}
		const rebuiltRoute = {}
		let url = askedRoute
		if (typeof askedRoute == 'object' && askedRoute !== null) {
			Object.assign(rebuiltRoute, Private.goToObjectRoute(askedRoute))
			const resolvedRoute = ROUTER.resolve(rebuiltRoute)
			url = window.location.protocol + '//' + window.location.host + resolvedRoute.resolved.fullPath
		}

		window.open(url, '_blank')
	},
	goTo: function (askedRoute, replaceInHistory = false) {
		const rebuiltRoute = {}
		let resolvedRoute = null
		if (typeof askedRoute === 'string') {
			const stringRouteResult = Private.goToStringRoute(askedRoute)
			if (typeof stringRouteResult === 'string') {
				resolvedRoute = stringRouteResult
			} else if (typeof stringRouteResult === 'object') {
				Object.assign(rebuiltRoute, stringRouteResult)
				resolvedRoute = { ...ROUTER.resolve(rebuiltRoute).resolved }
			}
		} else if (typeof askedRoute == 'object' && askedRoute !== null) {
			Object.assign(rebuiltRoute, Private.goToObjectRoute(askedRoute))
			resolvedRoute = { ...ROUTER.resolve(rebuiltRoute).resolved }
		}
		if (!resolvedRoute) {
			throw new Error('No resolved route.')
		} else if (typeof resolvedRoute === 'string' && resolvedRoute.toLowerCase().startsWith('http')) {
			Private.goToNewTab(resolvedRoute)
		} else if (typeof resolvedRoute == 'object' && replaceInHistory) {
			ROUTER.replace(resolvedRoute)
		} else {
			ROUTER.push(resolvedRoute)
			BUS.emit(EVENTS.INCREMENT_NAVIGATION_COUNTER)
		}
	},
	goToStringRoute: function (askedRoute) {
		let result = askedRoute
		if (typeof askedRoute === 'string' && !askedRoute.includes('/')) {
			result = {}
			result.name = askedRoute
			const decryptedContext = ROUTER.decryptContext(ROUTER.currentRoute?.params?.context) || {}
			if (!decryptedContext.accounting_firm_id) {
				decryptedContext.accounting_firm_id = STORE.state.accountingFirm?.selected?.id
			}
			if (!decryptedContext.vendor_id) {
				decryptedContext.vendor_id = STORE.state.company?.selected?.id
			}
			result.params = {
				context: ROUTER.encryptContext(decryptedContext.accounting_firm_id, decryptedContext.vendor_id)
			}
		}
		return result
	},
	goToObjectRoute: function (askedRoute) {
		let result = {}
		if (typeof askedRoute == 'object' && askedRoute !== null) {
			Object.assign(result, Private.getGoToObjectRouteNameAndOrPath(askedRoute))
			Object.assign(result, { params: Private.getGoToObjectRouteParams(askedRoute) })
			Object.assign(result, { query: { ...askedRoute.query } })
		}
		return result
	},
	getGoToObjectRouteNameAndOrPath: function (askedRoute) {
		if (typeof askedRoute == 'object' && askedRoute != null && !askedRoute.name && !askedRoute.path) {
			askedRoute.name = ROUTER.currentRoute.name
		}
		return askedRoute
	},
	getGoToObjectRouteParams: function (askedRoute) {
		let result = { ...askedRoute.params }
		if (typeof askedRoute == 'object' && askedRoute !== null) {
			let accountingFirmId
			let context
			let vendorId
			if (askedRoute.params?.hasOwnProperty('accounting_firm_id')) {
				accountingFirmId = askedRoute.params.accounting_firm_id
			} else {
				accountingFirmId = STORE.state.accountingFirm?.selected?.id
			}
			if (askedRoute.params?.hasOwnProperty('vendor_id')) {
				vendorId = askedRoute.params.vendor_id
			} else {
				vendorId = STORE.state.company?.selected?.id
			}
			if (askedRoute.params?.context) {
				context = askedRoute.params.context
			} else if (!accountingFirmId) {
				context = ROUTER.currentRoute.params?.context
			} else {
				context = ROUTER.encryptContext(accountingFirmId, vendorId)
			}

			delete result.accounting_firm_id
			delete result.vendor_id

			result.context = context
		}
		return result
	},
	getDefaultAccountingFirm: function () {
		let result = Private.getDefaultAccountingFirmFromLocalStorage()
		if (!result) {
			result = Private.getDefaultAccountingFirmFromWhiteLabelData()
		}
		if (!result) {
			result = Private.getDefaultAccountingFirmMatchingDomain()
		}
		if (!result) {
			result = Private.getArbitraryDefaultAccountingFirm()
		}
		return result
	},
	getDefaultAccountingFirmFromLocalStorage: function () {
		let result = null
		const accountingFirmList = STORE.state.accountingFirm?.list
		if (accountingFirmList && accountingFirmList.length > 0 && localStorage.getItem('lastSelectedAccountingFirmId')) {
			const domain = window.location.hostname
			let accountingFirmId = parseInt(localStorage.getItem('lastSelectedAccountingFirmId'))
			result = accountingFirmList.find(accountingFirm => accountingFirm.id == accountingFirmId && accountingFirm.domain == domain)
		}
		return result
	},
	getDefaultAccountingFirmFromWhiteLabelData: function () {
		let result = null
		const accountingFirmList = STORE.state.accountingFirm?.list
		if (accountingFirmList && accountingFirmList.length > 0 && STORE.state.whitelabel.accountingFirmId) {
			const domain = window.location.hostname
			result = accountingFirmList.find(accountingFirm => accountingFirm.id == STORE.state.whitelabel.accountingFirmId && accountingFirm.domain == domain)
		}
		return result
	},
	getDefaultAccountingFirmMatchingDomain: function () {
		let result = null
		const accountingFirmList = STORE.state.accountingFirm?.list
		if (accountingFirmList && accountingFirmList.length > 0) {
			const domain = window.location.hostname
			result = accountingFirmList.find(accountingFirm => accountingFirm.domain == domain)
		}
		return result
	},
	getArbitraryDefaultAccountingFirm: function () {
		let result = null
		const accountingFirmList = STORE.state.accountingFirm?.list
		if (accountingFirmList && accountingFirmList.length > 0) {
			result = accountingFirmList[0]
		}
		return result
	},
	setSelectedAccountingFirmIntoLocalStorage: function (accountingFirmId) {
		localStorage.removeItem('lastSelectedAccountingFirmId')
		if (accountingFirmId) {
			localStorage.setItem('lastSelectedAccountingFirmId', accountingFirmId)
		}
	},
	selectAccountingFirm: function (accountingFirmId) {
		const route = { ...ROUTER.currentRoute }

		if (route.params?.accounting_firm_id != accountingFirmId) {
			const context = ROUTER.encryptContext(accountingFirmId)
			Private.goTo({
				name: route.name,
				params: {
					context
				}
			})
		}
	},
	setAccountingFirm: function (accountingFirm, params = {}) {
		return Promise.all([
			STORE.dispatch('accountingFirm/setAccountingFirm', accountingFirm),
			STORE.dispatch('auth/setIsAccountant', accountingFirm.isAccountant),
			STORE.dispatch('company/reset', [])
		])
			.then(() => {
				let result = Promise.resolve()
				BUS.emit(EVENTS.ACCOUNTING_FIRM_SELECTED, accountingFirm)
				Private.setSelectedAccountingFirmIntoLocalStorage(accountingFirm.id)
				if (accountingFirm?.isAccountant) {
					result = Private.loadModules(accountingFirm.id, null,(({ is_mobile_app, is_generic_mobile_app, is_webapp }) => ({ is_mobile_app, is_generic_mobile_app, is_webapp }))(params))
				}
				return result
			})
			.then(() => {
				return Private.loadCompanies()
			})
			.then(() => {
				let result
				const currentRoute = ROUTER.currentRoute
				if (params.vendor_id) {
					const vendor = STORE.state.company.list.find(({ id }) => id == params.vendor_id)
					if (vendor) {
						result = Private.setVendor(vendor, (({ is_mobile_app, is_generic_mobile_app, is_webapp }) => ({ is_mobile_app, is_generic_mobile_app, is_webapp }))(params))
					}
				}
				if (!result) {
					result = Private.getDefaultVendor().then(vendor => {
						const promise = Private.setVendor(vendor, (({ is_mobile_app, is_generic_mobile_app, is_webapp }) => ({ is_mobile_app, is_generic_mobile_app, is_webapp }))(params))
						if (currentRoute.matched.some(route => route.path.includes(':context'))) {
							Private.selectVendor(vendor?.id)
						}
						return promise
					})
				}
				return result
			})
	},
	loadModules: function (accountingFirmId, vendorId = null, params) {
		let promise = null
 		if (vendorId) {
			promise = API.getModulesAsCompanyUser(accountingFirmId, vendorId, params)
		} else {
			promise = API.getModulesAsAccountant(accountingFirmId, params)
		}
		return promise
			.then(response => response.data.data)
			.then(modules => {
				const promises = []
				modules.forEach(mod => {
					if (mod.name === Private.GEST4U && vendorId) {
						promises.push(Private.validateGest4UAccess(accountingFirmId, vendorId, modules))
					}
				})
				return Promise.all(promises).then(() => modules)
			})
			.then(modules => {
				return Private.initModuleStore(modules)
			})
	},
	validateGest4UAccess: function (accountingFirmId, vendorId, modules) {
		return Gest4UApi.hasAccessToGest4u(accountingFirmId, vendorId)
			.then(response => response.data.data)
			.then(({ result: hasAccess }) => {
				if (!hasAccess) {
					const index = modules.findIndex(mod => mod.name === Private.GEST4U)
					if (index !== -1) {
						modules.splice(index, 1)
					}
				}
			})
	},
	initModuleStore: function (modules) {
		const homeModules = []
		const navbarModules = []
		const settingsModules = []
		const list = []
		modules.forEach(welybModule => {
			list.push(welybModule)
			if (welybModule.is_home) {
				homeModules.push(welybModule)
			}
			if (welybModule.is_navbar) {
				navbarModules.push(welybModule)
			}
			if (welybModule.is_settings) {
				settingsModules.push(welybModule)
			}
		})
		return Promise.all([
			STORE.dispatch('modules/setList', list),
			STORE.dispatch('modules/setHome', homeModules),
			STORE.dispatch('modules/setNavbar', navbarModules),
			STORE.dispatch('modules/setSettings', settingsModules)
		])
	},
	compareValues: function (key, order = 'asc') {
		const collator = new Intl.Collator(i18n.locale)
		return (a, b) => {
			let comparison
			if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
				comparison = 0
			} else {
				comparison = collator.compare(a[key], b[key])
			}
			return order === 'desc' ? comparison * -1 : comparison
		}
	},
	selectDefaultAccountingFirm: function () {
		const defaultAccountingFirm = Private.getDefaultAccountingFirm()
		let result = Promise.resolve()
		if (defaultAccountingFirm.domain === window.location.hostname) {
			result = Private.selectAccountingFirm(defaultAccountingFirm.id)
		} else {
			Private.redirectToAccountingFirmWorkspace(defaultAccountingFirm, true)
		}
		return result
	},
	selectVendor: function (vendorId) {
		const accountingFirm = STORE.state.accountingFirm.selected
		const route = { ...ROUTER.currentRoute }

		if (route.params?.vendor_id != vendorId) {
			const context = ROUTER.encryptContext(accountingFirm.id, vendorId)
			Private.goTo({
				name: route.name,
				params: {
					context
				}
			})
		}
	},
	setVendor: function (vendor, params = {}) {
		return STORE.dispatch('company/setCompany', vendor)
			.then(() => {
				let result = Promise.resolve()
				const accountingFirm = STORE.state.accountingFirm?.selected
				if (!accountingFirm?.isAccountant) {
					result = Private.loadModules(accountingFirm.id, vendor.id, params)
				}
				return result
			})
			.then(() => {
				let result = Promise.resolve()
				if (vendor) {
					result = Private.loadCompanyUserRole(vendor.id)
				}
				return result
			})
	},
	redirectToAccountingFirmWorkspace: function (accountingFirm, includePath = false) {
		const { port, protocol } = window.location

		let result = `${protocol}//${accountingFirm.domain}${port ? ':' + port : ''}/autologin?a_id=${accountingFirm.id}&token=${STORE.state.auth.token}`

		if (includePath) {
			result += `&redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
		} else {
			result += `&redirect=${encodeURIComponent(ROUTER.currentRoute.name)}`
		}

		window.location.href = result
	},
	getDefaultVendor: function () {
		let result
		const companies = STORE.state.company.list
		if (companies.length === 0) {
			result = Private.loadCompanies()
		} else {
			result = Promise.resolve()
		}
		return result.then(() => {
			let vendorId
			if (ROUTER.currentRoute?.params?.context) {
				vendorId = ROUTER.decryptContext(ROUTER.currentRoute.params.context).vendor_id
			}
			let subResult
			if (!vendorId && localStorage.getItem('lastSelectedCompanyId')) {
				vendorId = parseInt(localStorage.getItem('lastSelectedCompanyId'))
			}
			if (vendorId) {
				subResult = companies?.find(company => {
					return company.id === vendorId
				})
			}
			if (!subResult) {
				localStorage.removeItem('lastSelectedCompanyId')
				const companiesCounters = []
				if (companies) {
					companiesCounters.push(...companies.map(company => company.views_counter))
				}
				const companyMaxViews = Math.max(...companiesCounters)
				subResult = companies?.find(company => company.views_counter === companyMaxViews)
			}
			return subResult
		})
	},
	loadCompanyUserRole: function (vendorId) {
		return API.loadCompanyUserRole(vendorId)
			.then(res => res.data.data)
			.then(role => STORE.dispatch('company/setCompanyUserRole', role))
	},
	selectDefaultVendor: function () {
		return Private.getDefaultVendor().then(defaultVendor => Private.selectVendor(defaultVendor?.id))
	},
	loadCompanies: function () {
		let result
		if (STORE.state.accountingFirm.selected) {
			result = API.getAccountingFirmUserCompanyList(STORE.state.accountingFirm.selected.id).then(response => {
				const sortedCompaniesList = []
				if (response.data && response.data.length > 0) {
					sortedCompaniesList.push(...response.data.sort(Private.compareValues('company', 'asc')))
				} else {
					BUS.emit(EVENTS.SNACKBAR_ERROR, window.vueInstance.$t('user.error.no_company_assigned'))
				}
				return Private.store.setCompanies(sortedCompaniesList)
			})
		} else {
			result = Private.store.setCompanies(null)
		}
		return result
	},
	getDarkModeFromOS: function () {
		let userOSdarkMode = null
		if (window.matchMedia) {
			if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
				userOSdarkMode = 'dark'
			} else {
				userOSdarkMode = 'light'
			}
		}
		return userOSdarkMode
	},
	getDarkModeFromUserStore: function () {
		let storeDarkMode = null
		if (typeof STORE.state.user.darkMode === 'boolean') {
			if (STORE.state.user.darkMode) {
				storeDarkMode = 'dark'
			} else {
				storeDarkMode = 'light'
			}
		} else if (STORE.state.user.darkMode && typeof STORE.state.user.darkMode === 'string') {
			storeDarkMode = STORE.state.user.darkMode
		}
		return storeDarkMode
	},
	getDarkModeFromUrl: function () {
		const urlParams = new URLSearchParams(window.location.search)
		let urlDarkMode = null
		let urlDarkModeParam = urlParams.get('darkMode')
		if (typeof urlDarkModeParam === 'string' &&  (urlDarkModeParam === 'true' || urlDarkModeParam === 'false')) {
			urlDarkModeParam = (urlDarkModeParam === 'true')
		}
		if (typeof urlDarkModeParam === 'boolean') {
			if (urlDarkModeParam) {
				urlDarkMode = 'dark'
			} else {
				urlDarkMode = 'light'
			}
		}
		return urlDarkMode

	},

	store: {
		setCompanies: function (companies) {
			return STORE.dispatch('company/setCompanies', companies)
		}
	}
}

// -------
// Exports
// -------
export default {
	goTo: Private.goTo,
	goToNewTab: Private.goToNewTab,
	compareValues: Private.compareValues,
	destroyComponent: function (component) {
		if (component?.$parent?.$el) {
			component?.$parent?.$el.removeChild(component?.$el)
		}
		component.$destroy()
	},
	mountComponent: function (componentToMount, parentComponent = null, data = {}) {
		if (parentComponent) {
			data.parent = parentComponent
		} else {
			data.parent = window.vueInstance
		}
		const componentInstance = Vue.extend(componentToMount)
		const component = new componentInstance(data).$mount()
		let parentElement = null

		if (parentComponent?.$el) {
			parentElement = parentComponent.$el
		} else {
			parentElement = document.getElementById('app')
		}
		parentElement.appendChild(component.$el)

		return component
	},
	initDarkMode: function () {
		// Get user OS setting
		const userOSdarkMode = Private.getDarkModeFromOS()
		// Get user local storage setting
		const localStorageDarkMode = localStorage.getItem('darkMode')
		// Get user store setting
		const storeDarkMode = Private.getDarkModeFromUserStore()
		// Get url dark mode
		const urlDarkMode = Private.getDarkModeFromUrl()
		// Determine dark mode status
		let darkMode
		if (urlDarkMode) {
			darkMode = urlDarkMode
		} else if (storeDarkMode) {
			darkMode = storeDarkMode
		} else if (localStorageDarkMode) {
			darkMode = localStorageDarkMode
		} else if (userOSdarkMode) {
			darkMode = userOSdarkMode
		} else {
			darkMode = 'light'
		}
		// Set local storage to found value, no matter previous value was
		localStorage.setItem('darkMode', darkMode)
		// Set store to found value, no matter previous value was
		return STORE.dispatch('user/setDarkMode', darkMode === 'dark')
	},
	setDarkMode: function (value) {
		let strValue = null
		let boolValue = null
		if (value != null && typeof value === 'string') {
			if (value === 'ligth') {
				strValue = 'light'
				boolValue = false
			} else if (value === 'dark') {
				strValue = 'dark'
				boolValue = true
			}
		} else if (value != null && typeof value === 'boolean') {
			boolValue = value
			if (value) {
				strValue = 'dark'
			} else {
				strValue = 'light'
			}
		}
		localStorage.setItem('darkMode', strValue)
		return STORE.dispatch('user/setDarkMode', boolValue)
	},
	changePassword: function (password, passwordConfirmation) {
		let result = Promise.resolve()
		if (!password) {
			BUS.emit(EVENTS.SNACKBAR_ERROR, window.vueInstance.$t('errors.missing_password'))
		} else if (!passwordConfirmation) {
			BUS.emit(EVENTS.SNACKBAR_ERROR, window.vueInstance.$t('errors.missing_password_confirmation'))
		} else {
			result = API.changePassword({
				password: password,
				password_confirmation: passwordConfirmation
			}).finally(() => {
				return STORE.dispatch('auth/reset').finally(() => {
					return Private.goTo('signin')
				})
			})
		}
		return result
	},
	resetPasswordSendMail: function (email = null, domains = null) {
		let result = Promise.resolve()
		if (!email) {
			BUS.emit(EVENTS.SNACKBAR_ERROR, window.vueInstance.$t('errors.missing_email'))
		} else {
			result = API.resetPasswordSendMail({ email: email, domains: domains })
		}
		return result
	},
	resetPasswordSubmit: function (password, passwordConfirmation, token) {
		return API.resetPasswordSubmit({
			password: password,
			password_confirmation: passwordConfirmation,
			token: token
		})
	},
	createPassword: function (password, passwordConfirmation, cgu, token) {
		return API.createPassword({
			password: password,
			password_confirmation: passwordConfirmation,
			cgu: cgu,
			token: token
		})
	},
	getPasswordReset: function (token) {
		return API.getPasswordReset(token)
	},
	loadAccountingFirms: function () {
		let result = Promise.resolve()
		if (!STORE.state.accountingFirm.list || STORE.state.accountingFirm.list.length === 0) {
			result = Promise.all([API.getAccountingFirm(), API.getUserAccountingFirms()]).then(accountingFirmsLists => {
				let accountingFirms = []
				let accountantAccountingFirms = accountingFirmsLists[0] ? accountingFirmsLists[0].data : []
				accountantAccountingFirms.forEach(accountantAccountingFirm => {
					accountantAccountingFirm.isAccountant = true
					accountantAccountingFirm.isAccountantAdmin = accountantAccountingFirm.pivot && accountantAccountingFirm.pivot.role === 'admin'
					accountantAccountingFirm.isGroupAdmin = accountantAccountingFirm.pivot && accountantAccountingFirm.pivot.is_group_admin
					accountingFirms.push(accountantAccountingFirm)
				})
				let clientAccountingFirms = accountingFirmsLists[1] ? accountingFirmsLists[1].data : []
				clientAccountingFirms.forEach(clientAccountingFirm => {
					if (accountingFirms.filter(accountingFirm => accountingFirm.id === clientAccountingFirm.id).length === 0) {
						clientAccountingFirm.isAccountant = false
						accountingFirms.push(clientAccountingFirm)
					}
				})
				return STORE.dispatch('accountingFirm/setAccountingFirms', accountingFirms.sort(Private.compareValues('name', 'asc')))
			})
		}
		return result
	},
	loadUser: function () {
		return API.getUserData()
			.then(res => res.data)
			.then(userData => {
				return STORE.dispatch('user/setUser', userData).then(() => userData)
			})
			.then(userData => {
				BUS.emit(EVENTS.UPDATE_THIRD_PARTIES)
				BUS.emit(EVENTS.USER_UPDATED, userData)
			})
	},
	addAccountingFirm: function (accountingFirm) {
		return STORE.dispatch('accountingFirm/addAccountingFirm', accountingFirm)
	},
	removeAccountingFirm: function (accountingFirm) {
		return STORE.dispatch('accountingFirm/removeAccountingFirm', accountingFirm).then(() => {
			const currentAccountingFirm = STORE.state.accountingFirm?.selected
			if (currentAccountingFirm.id == accountingFirm.id) {
				Private.selectDefaultAccountingFirm()
			}
		})
	},
	updateAccountingFirm: function (accountingFirm) {
		return STORE.dispatch('accountingFirm/updateAccountingFirm', accountingFirm)
	},
	redirectToAccountingFirmWorkspace: Private.redirectToAccountingFirmWorkspace,
	selectAccountingFirm: Private.selectAccountingFirm,
	selectDefaultAccountingFirm: Private.selectDefaultAccountingFirm,
	setAccountingFirm: Private.setAccountingFirm,
	setDefaultAccountingFirm: function (params) {
		const accountingFirm = Private.getDefaultAccountingFirm()
		return Private.setAccountingFirm(accountingFirm, params)
	},
	loadCompanies: Private.loadCompanies,
	getDefaultVendor: Private.getDefaultVendor,
	selectVendor: Private.selectVendor,
	setVendor: Private.setVendor,
	loadCompanyUserRole: Private.loadCompanyUserRole,
	selectDefaultVendor: Private.selectDefaultVendor,
	copyToClipboard: function (str) {
		const el = document.createElement('textarea')
		el.value = str
		el.setAttribute('readonly', '')
		el.style = { position: 'absolute', left: '-9999px' }
		document.body.appendChild(el)
		el.select()
		document.execCommand('copy')
		document.body.removeChild(el)
		return Promise.resolve()
	},
	openDialog: function (data) {
		BUS.emit(EVENTS.DISPLAY_DIALOG, data)
		return data.component
	},
	hasModule: function (moduleName) {
		return STORE.getters['modules/activeModules'].has(moduleName)
	},
	getHubspotToken: function (email, lastName, firstName) {
		return API.getHubspotToken({
			email: email,
			lastName: lastName,
			firstName: firstName
		}).then(res => res.data.data)
	},
	getWebDavUnSupportedChars: function () {
		return ['€', '§', '¤', '£', '°']
	}
}
