// Container

// Pages
const AutoLoginCallback = () => import('@/views/AutoLoginCallBack')

export default [
	{
		component: AutoLoginCallback,
		meta: {
			auto_login: false,
			historize: false
		},
		name: 'autologin',
		path: 'autologin',
		props: route => {
			let redirect
			if (route.query.redirect) {
				redirect = decodeURIComponent(route.query.redirect)
			}
			return {
				redirect,
				refreshToken: route.query.refresh_token !== false && route.query.refresh_token !== 'false',
				token: route.query.token
			}
		}
	}
]
